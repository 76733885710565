import "react-native-gesture-handler";
import React, {useEffect, useRef} from "react";
import {connect, useDispatch} from "react-redux";
import {NavigationContainer} from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import {useTranslation} from "react-i18next";
import {getLoggedUser, getUserInfo} from "./actions/actionsAuth";
import * as Sentry from "sentry-expo";
import SuccessModalContainer from "./Containers/SuccessModalContainer";
import DailyRewardModalContainer from "./Containers/DailyRewardModalContainer";
import ErrorModalContainer from "./Containers/ErrorModalContainer";
import {LoginStackNavigator} from "./stackNavigators/LoginStackNavigator";
import {Attendance, State, User} from "./state";
import {fetchTodayAttendance} from "./actions/actionsAttendance";
import {registerForPushNotificationsAsync} from "./utils/notificationsUtils";
import {updateExpoPushToken} from "./actions/actions";
import {HomeStackNavigator} from "./stackNavigators/HomeStackNavigator";
import {Severity} from "@sentry/types";

interface AppProps {
  isCallingAuthApi: boolean;
  currentUserName: string;
  currentUser?: User;
  apiKey: string;
  language: string;
  currentAttendance?: Attendance;
  todayDate: string;
  openingTime: string;
  closingTime: string;
}

const mapStateToProps = (state: State) => {
  return {
    isCallingAuthApi: state.session.isLoggingIn
      || state.session.isLoggingOut
      || state.session.isGettingLoggedUser
      || state.session.isGettingUserInfo
      || state.session.isGettingApiKeyFromSecureStore,
    apiKey: state.session.apiKey,
    currentUserName: state.control.currentUserName,
    currentUser: state.control.currentUser,
    language: state.control.language,
    currentAttendance: state.control.currentAttendance,
    todayDate: state.control.todayDate,
    openingTime: state.control.openingTime,
    closingTime: state.control.closingTime,
  }
}

const prefix = Linking.createURL('/');

const TapApplication = (props: AppProps) => {

  const isCallingAuthApi = props.isCallingAuthApi;
  const currentUserName = props.currentUserName;
  const currentUser = props.currentUser;
  const currentAttendance = props.currentAttendance;
  const employeeName = currentUser?.employeeName;
  const attendanceName = currentAttendance?.name;
  const apiKey = props.apiKey;
  const language = props.language;
  const todayDate = props.todayDate;
  const notificationListener = useRef<any>();
  const responseListener = useRef<any>();

  const dispatch = useDispatch();
  const {i18n} = useTranslation();

  const linking = {
    prefixes: [prefix],
    config: {
      screens: {
        LoginScreen: "auto-login/:credentials",
        HomeScreen: '*'
      },
    }
  };

  useEffect(() => {
    registerForPushNotificationsAsync()
      .then(token => {
        if (token) {
          dispatch(updateExpoPushToken(token));
        }
      });
    notificationListener.current = Notifications.addNotificationReceivedListener(() => {
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(() => {
      Notifications.dismissAllNotificationsAsync();
      // TODO: (ti-20220621) will be enabled when deeplink feature is ready
      // if (response.actionIdentifier === TAP_IN_BUTTON) {
      //   const location = await getLocationFromNotificationAction();
      //   if (employeeName) {
      //     Analytics.logEvent("check_in");
      //     dispatch(
      //       postCheckInAttendance(
      //         employeeName,
      //         location ? location.coords.latitude : undefined,
      //         location ? location.coords.longitude : undefined
      //       )
      //     );
      //     return Promise.resolve();
      //   } else {
      //     return Promise.reject(String(t('modal.errorModal.usernameError')));
      //   }
      // } else if (response.actionIdentifier === TAP_OUT_BUTTON) {
      //   const location = await getLocationFromNotificationAction();
      //   if (attendanceName) {
      //     Analytics.logEvent("check_out");
      //     dispatch(
      //       submitAttendance(
      //         attendanceName,
      //         location ? location.coords.latitude : undefined,
      //         location ? location.coords.longitude : undefined
      //       )
      //     );
      //     return Promise.resolve();
      //   } else {
      //     return Promise.reject(String(t('modal.errorModal.usernameError')));
      //   }
      // }
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  useEffect(() => {
    if (apiKey && !currentUserName && !isCallingAuthApi) {
      dispatch(getLoggedUser());
    }
  }, [apiKey, isCallingAuthApi, dispatch]);

  useEffect(() => {
    if (apiKey && currentUserName && !currentUser && !isCallingAuthApi) {
      dispatch(getUserInfo(currentUserName));
    }
  }, [apiKey, currentUserName, isCallingAuthApi, dispatch]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (employeeName) {
      dispatch(fetchTodayAttendance(employeeName, todayDate));
    }
  }, [employeeName, todayDate]);

  useEffect(() => {
    if (currentUserName) {
      Sentry.Native.configureScope((scope) => {
        scope.setLevel(Severity.Error);
        scope.setUser({
          email: currentUserName,
        });
      });
    }
  }, [currentUserName]);

  return (
    <React.Fragment>
      {
        <NavigationContainer linking={linking}>
          {
            !apiKey ?
              <LoginStackNavigator />
              :
              <HomeStackNavigator />
          }
        </NavigationContainer>
      }
      <ErrorModalContainer/>
      <SuccessModalContainer/>
      <DailyRewardModalContainer/>
    </React.Fragment>
  )
}

export default connect(mapStateToProps)(TapApplication);
