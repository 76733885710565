import {connect, useDispatch} from "react-redux";
import {
  ActivityTemplate, Project,
  State,
  Timelog,
  TimeSheetTemplate,
  TimesheetTemplateModalData,
  TimesheetTemplateMode
} from "../state";
import {TimesheetTemplateScreen} from "../screens/TimesheetTemplateScreen";
import React, {useEffect, useState} from "react";
import {CommonActions, useNavigation, useRoute} from "@react-navigation/native";
import {addNewTimelog, updateTimesheetTemplates} from "../actions/actionsTimesheet";
import {formatDateYYYYMMDD} from "../lib/dateFormat";
import {updateSuccessModalStatus} from "../actions/actions";
import i18n from "i18next";

interface TimesheetTemplateScreenContainerProps {
  timesheetTemplates: TimeSheetTemplate[];
  todayTimelogs: Timelog[];
  projects: Project[];
  activityTypes: string[];
  employee?: string;
  isStoringTimelogs: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    employee: state.control.currentUser?.employeeName,
    timesheetTemplates: state.control.timesheetTemplates,
    todayTimelogs: state.data.todayTimelogs,
    projects: state.data.projects,
    activityTypes: state.data.activityTypes,
    isStoringTimelogs: state.session.timelogs.isStoring,
  };
};

const TimesheetTemplateScreenContainer = (props: TimesheetTemplateScreenContainerProps) => {

  const initialSelectedTimesheetTemplate: TimesheetTemplateModalData = {
    projectTitle: '',
    activityTitle: '',
    description: '',
    workingHourDurations: 0,
    workingMinuteDurations: 0,
    activityIndex: 0,
    isSelected: false,
  };

  const [timesheetTemplates, setTimesheetTemplates] =
    useState<TimeSheetTemplate[]>([]);
  const [singleTimesheetTemplateData, setSingleTimesheetTemplateData] =
    useState<TimesheetTemplateModalData>(initialSelectedTimesheetTemplate);
  const [modalMode, setModalMode] = useState<TimesheetTemplateMode>("Create");
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigation = useNavigation();
  const router = useRoute();
  const params: any = router.params;
  const selectedDate = params.selectedDate;

  useEffect(() => {
    let counter = 0;
    props.timesheetTemplates.forEach(template => {
      template.activities.forEach(activity => {
        if (activity.isSelected) {
          counter += 1;
        }
      });
    });
    setSelectedTemplate(counter);
    setTimesheetTemplates(props.timesheetTemplates);
  }, [props.timesheetTemplates]);

  useEffect(() => {
    const updatedTimesheetTemplates = [...props.timesheetTemplates];
    updatedTimesheetTemplates.forEach((template, index) => {
      const updatedActivity = [...template.activities];
      updatedActivity.forEach((activity, index) => {
        updatedActivity[index] = {
          ...activity,
          isSelected: false,
        };
      });
      updatedTimesheetTemplates[index] = {
        ...updatedTimesheetTemplates[index],
        activities: updatedActivity,
      };
    });
    dispatch(updateTimesheetTemplates(updatedTimesheetTemplates));
  }, []);

  const handleSettingsOnPress = () => {
    setIsEditMode(true);
  };

  const handleSaveOnPress = () => {
    dispatch(updateTimesheetTemplates(timesheetTemplates));
    setIsEditMode(false);
  };

  const handleDiscardTimesheetTemplateOnPress = () => {
    setIsEditMode(false);
    setTimesheetTemplates(props.timesheetTemplates);
  };

  const handleOpenNewTemplateModal = () => {
    setModalMode('Create');
    setIsEditTemplateModalOpen(true);
  };

  const handleOpenEditTemplateModal = (
    activity: ActivityTemplate,
    projectTitle: string,
    activityIndex: number,
  ) => {
    setSingleTimesheetTemplateData({
      projectTitle: projectTitle,
      activityIndex: activityIndex,
      ...activity,
    });
    setModalMode('Edit');
    setIsEditTemplateModalOpen(true);
  };

  const handleCloseEditTemplateModal = () => {
    setIsEditTemplateModalOpen(false);
    setSingleTimesheetTemplateData(initialSelectedTimesheetTemplate);
  };

  const handleSelectSingleActivityTemplate = (
    activity: ActivityTemplate,
    projectTitle: string,
    activityIndex: number,
  ) => {
    let updatedTimesheetTemplates = [...timesheetTemplates];
    let updateSelectedActivityTemplate = { ...activity };
    const selectedTimesheetTemplateIndex = updatedTimesheetTemplates
      .findIndex(template => template.projectTitle === projectTitle);
    const activityTemplates = updatedTimesheetTemplates[selectedTimesheetTemplateIndex].activities;
    updateSelectedActivityTemplate = {
      ...updateSelectedActivityTemplate,
      isSelected: !updateSelectedActivityTemplate.isSelected,
    };
    const updatedActivityTemplates = [
      ...activityTemplates.slice(0, activityIndex),
      updateSelectedActivityTemplate,
      ...activityTemplates.slice(activityIndex + 1),
    ];
    updatedTimesheetTemplates[selectedTimesheetTemplateIndex] = {
      ...updatedTimesheetTemplates[selectedTimesheetTemplateIndex],
      activities: updatedActivityTemplates,
    };
    dispatch(updateTimesheetTemplates(updatedTimesheetTemplates));
  };

  const handleValueOnChange = (field: string, value: string) => {
    let updateSelectedTimesheetTemplate = { ...singleTimesheetTemplateData };
    updateSelectedTimesheetTemplate = {
      ...updateSelectedTimesheetTemplate,
      [field]: value,
    };
    setSingleTimesheetTemplateData(updateSelectedTimesheetTemplate);
  };

  const handleDeleteSingleTemplateOnPress = (
    singleTimesheetTemplate: TimeSheetTemplate,
    activityIndex: number,
  ) => {
    let updatedTimesheetTemplates = [...timesheetTemplates];
    const selectedTimesheetTemplateIndex = updatedTimesheetTemplates
      .findIndex(template => template.projectTitle === singleTimesheetTemplate.projectTitle);
    const activityTemplates = [...updatedTimesheetTemplates[selectedTimesheetTemplateIndex].activities];
    if (activityTemplates.length === 1) {
      updatedTimesheetTemplates = [
        ...updatedTimesheetTemplates.slice(0, selectedTimesheetTemplateIndex),
        ...updatedTimesheetTemplates.slice(selectedTimesheetTemplateIndex + 1),
      ];
    } else {
      const updatedActivityTemplates = [
        ...activityTemplates.slice(0, activityIndex),
        ...activityTemplates.slice(activityIndex + 1),
      ];
      updatedTimesheetTemplates[selectedTimesheetTemplateIndex] = {
        ...updatedTimesheetTemplates[selectedTimesheetTemplateIndex],
        activities: updatedActivityTemplates,
      };
    }
    setTimesheetTemplates(updatedTimesheetTemplates);
  };

  const handleSaveEditedActivityTemplate = () => {
    let updatedTimesheetTemplates = [...timesheetTemplates];
    const selectedTimesheetTemplateIndex = updatedTimesheetTemplates
      .findIndex(template => template.projectTitle === singleTimesheetTemplateData.projectTitle);
    const activityTemplates = updatedTimesheetTemplates[selectedTimesheetTemplateIndex].activities;
    const updatedActivityTemplates = [
      ...activityTemplates.slice(0, singleTimesheetTemplateData.activityIndex),
      singleTimesheetTemplateData,
      ...activityTemplates.slice(singleTimesheetTemplateData.activityIndex + 1),
    ];
    updatedTimesheetTemplates[selectedTimesheetTemplateIndex] = {
      ...updatedTimesheetTemplates[selectedTimesheetTemplateIndex],
      activities: updatedActivityTemplates,
    };
    if (!isEditMode) {
      dispatch(updateTimesheetTemplates(updatedTimesheetTemplates));
      handleCloseEditTemplateModal();
      return;
    }
    setTimesheetTemplates(updatedTimesheetTemplates);
    handleCloseEditTemplateModal();
  };

  const handleCreateNewTemplate = () => {
    let newTimesheetTemplates = [...timesheetTemplates];
    const existingProjectIndex = timesheetTemplates
      .findIndex(template => template.projectTitle === singleTimesheetTemplateData.projectTitle);
    const newActivityTemplates: ActivityTemplate = {
      activityTitle: singleTimesheetTemplateData.activityTitle,
      workingHourDurations: singleTimesheetTemplateData.workingHourDurations,
      workingMinuteDurations: singleTimesheetTemplateData.workingMinuteDurations,
      description: singleTimesheetTemplateData.description,
      isSelected: false,
    };
    if (existingProjectIndex < 0) {
      newTimesheetTemplates.push({
        projectTitle: singleTimesheetTemplateData.projectTitle,
        activities: [newActivityTemplates],
      });
      setTimesheetTemplates(newTimesheetTemplates);
    } else {
      const updateActivityTemplates = [...newTimesheetTemplates[existingProjectIndex].activities];
      updateActivityTemplates.push(newActivityTemplates);
      newTimesheetTemplates[existingProjectIndex] = {
        ...newTimesheetTemplates[existingProjectIndex],
        activities: updateActivityTemplates,
      };
      setTimesheetTemplates(newTimesheetTemplates);
    }
    handleCloseEditTemplateModal();
  };

  const submitTimelogs = async () => {
    for (const template of props.timesheetTemplates) {
      for (const activity of template.activities) {
        if (activity.isSelected && props.employee) {
          const durationFull = Number(activity.workingHourDurations) + (Number(activity.workingMinuteDurations) / 60);
          await dispatch(addNewTimelog(
            props.employee,
            formatDateYYYYMMDD(selectedDate),
            template.projectTitle,
            activity.activityTitle,
            durationFull,
            true,
          ));
        }
      }
    }
    return;
  };

  const handleApplyOnPress = async () => {
    submitTimelogs()
      .then(() => {
        handleCloseTimesheetTemplateScreenOnPress();
        dispatch(updateSuccessModalStatus({
          isOpen: true,
          title: i18n.t('modal.successModal.title'),
          content: i18n.t('modal.successModal.submitTimeLogSuccess')
        }));
      });
  };

  const handleCloseTimesheetTemplateScreenOnPress = () => {
    navigation.dispatch(
      CommonActions.goBack()
    );
  };

  return (
    <TimesheetTemplateScreen
      timesheetTemplates={timesheetTemplates}
      singleTimesheetTemplateData={singleTimesheetTemplateData}
      projects={props.projects}
      activityTypes={props.activityTypes}
      modalMode={modalMode}
      selectedTemplate={selectedTemplate}
      isEditMode={isEditMode}
      isEditTemplateModalOpen={isEditTemplateModalOpen}
      isStoringTimelogs={props.isStoringTimelogs}
      handleSettingsOnPress={handleSettingsOnPress}
      handleSaveOnPress={handleSaveOnPress}
      handleApplyOnPress={handleApplyOnPress}
      handleOpenNewTemplateModal={handleOpenNewTemplateModal}
      handleOpenEditTemplateModal={handleOpenEditTemplateModal}
      handleCloseEditTemplateModal={handleCloseEditTemplateModal}
      handleDiscardTimesheetTemplateOnPress={handleDiscardTimesheetTemplateOnPress}
      handleCloseTimesheetTemplateScreenOnPress={handleCloseTimesheetTemplateScreenOnPress}
      handleValueOnChange={handleValueOnChange}
      handleSelectSingleActivityTemplate={handleSelectSingleActivityTemplate}
      handleDeleteSingleTemplateOnPress={handleDeleteSingleTemplateOnPress}
      handleSaveEditedActivityTemplate={handleSaveEditedActivityTemplate}
      handleCreateNewTemplate={handleCreateNewTemplate}
    />
  );
};

export default connect(mapStateToProps)(TimesheetTemplateScreenContainer);
